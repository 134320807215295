<template lang="pug">
  .permission-modal__table-row.permission-modal__table-row--body
    .permission-modal__table-cell {{ user.user_name }}
    .permission-modal__table-cell
      v-checkbox(
        v-model="user.permissions.read"
        @input="onUpdate")
    .permission-modal__table-cell
      v-checkbox(
        v-model="user.permissions.write"
        @input="onUpdate")
    .permission-modal__table-cell
      v-checkbox(
        v-model="user.permissions.change"
        @input="onUpdate")
    .permission-modal__table-cell
      v-checkbox(
        v-model="user.permissions.delete"
        @input="onUpdate")
    .permission-modal__table-cell
      v-checkbox(
        v-model="selectAll")

</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'PermissionModalTableRow',

  props: {
    user: {
      type: Object,
      required: true
    }
  },

  mounted () {
    this.init()
  },

  data: () => ({
    selectAll: false
  }),

  methods: {
    ...mapActions([
      'updatePermissions'
    ]),

    init () {
      this.selectAll = this.user.permissions.read &&
        this.user.permissions.write &&
        this.user.permissions.change &&
        this.user.permissions.delete
    },

    onUpdate () {
      const payload = {
        user_id: this.user.user_id,
        section_id: this.permissionSectionId,
        permission: {
          ...this.user.permissions
        }
      }
      this.updatePermissions(payload)
    }
  },

  computed: {
    ...mapGetters(['permissionSectionId'])
  },

  watch: {
    selectAll (value) {
      for (const key in this.user.permissions) {
        this.user.permissions[key] = value
      }
      this.onUpdate()
    },

    user () {
      this.init()
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
